import React, { useState } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import theme from "./theme"
import Header from "./header"
import Footer from "./footer"
import MobileNavigation from "./navigation/mobilenavigation"

import { GlobalStyles, Main } from "./globalstyles"

const Layout = ({ children }) => {
  const [modal, setModal] = useState(false)
  const [scroll, setScroll] = useState(0)

  const openModal = () => {
    setScroll(document.body.scrollTop || document.documentElement.scrollTop)

    // document.body.style.position = "fixed"
    // document.body.style.top = `-${scrollPosition}px`

    setModal(true)
  }

  const closeModal = async () => {
    // document.body.style.top = ""
    // document.body.style.position = ""
    // await window.scrollTo(0, scrollPosition)

    setModal(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Header openModal={openModal} />
        <GlobalStyles />
        <Main>{children}</Main>
        <Footer />
        {modal && <MobileNavigation closeModal={closeModal} />}
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
