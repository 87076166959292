import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

const DesktopNavigation = () => {
  const {
    menu: { edges },
  } = useStaticQuery(graphql`
    query DesktopNavigationQuery {
      menu: allWordpressWpApiMenusMenusItems(
        filter: { wordpress_id: { eq: 3 } }
      ) {
        edges {
          node {
            items {
              title
              order
              type
              wordpress_id
              wordpress_parent
              url
              wordpress_children {
                title
                wordpress_parent
                url
                wordpress_id
                order
              }
            }
          }
        }
      }
    }
  `)

  const [edge] = edges
  const { items } = edge.node

  const filteredItems = items.map(item => {
    if (item.url.includes("/kategoria/")) {
      const url = item.url.replace("/kategoria", "")
      return { ...item, url }
    }
    return item
  })

  return (
    <TopLevelNav>
      <NavItem>
        <StyledLink to="/" activeClassName="active">
          Home
        </StyledLink>
      </NavItem>
      {filteredItems.map(item => {
        return (
          <NavItem key={item.wordpress_id}>
            <StyledLink to={item.url} activeClassName="active">
              {item.title}
            </StyledLink>
            {item.wordpress_children ? (
              <NestedNav>
                {item.wordpress_children.map(child => (
                  <li key={child.wordpress_id}>
                    <StyledLink
                      to={child.url}
                      key={child.wordpress_id}
                      activeClassName="active"
                    >
                      {child.title}
                    </StyledLink>
                  </li>
                ))}
              </NestedNav>
            ) : null}
          </NavItem>
        )
      })}
    </TopLevelNav>
  )
}

const Navigation = styled.ul`
  margin: 0;
  list-style: none;
  background-color: #fac8c2;
`

const NestedNav = styled(Navigation)`
  opacity: 0;
  width: 240px;
  padding: 10px 20px;
  position: absolute;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;
  box-shadow: 0 10px 10px -7px rgba(0, 0, 0, 0.2);

  li {
    margin-bottom: 10px;
  }

  a {
    margin: 0;
    padding: 0;
    display: inline-block;
  }
`

const NavItem = styled.li`
  position: relative;
  margin: 0;

  &:hover {
    > a {
      text-decoration: underline;
    }

    ul {
      opacity: 1;
      visibility: visible;
    }
  }
`

const TopLevelNav = styled(Navigation)`
  display: none;
  justify-content: flex-end;

  @media (min-width: 768px) {
    display: flex;
  }
`

const StyledLink = styled(Link)`
  padding: 5px;
  margin: 0 5px;
  opacity: 0.85;
  position: relative;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 300ms ease-out;
  color: ${({ theme }) => theme.white};

  &:hover,
  &:hover:active {
    text-decoration: underline;
    color: ${({ theme }) => theme.white};
  }
`

export default DesktopNavigation
