import React from "react"

export default function Hamburger() {
  return (
    <svg
      width="42"
      height="30"
      viewBox="0 0 42 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <rect width="42" height="6" rx="2" />
        <rect width="42" height="6" rx="2" y="12" />
        <rect width="42" height="6" rx="2" y="24" />
      </g>
    </svg>
  )
}
