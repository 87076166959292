import React from "react"

export default function Close() {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="rotate(-45 13.657 24.042)"
        fill="currentColor"
        fillRule="evenodd"
      >
        <rect y="18" width="42" height="6" rx="2" />
        <rect
          transform="rotate(-90 21 21)"
          y="18"
          width="42"
          height="6"
          rx="2"
        />
      </g>
    </svg>
  )
}
