import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import Close from "../close"

const MobileNavigation = ({ closeModal }) => {
  const {
    menu: { edges },
  } = useStaticQuery(graphql`
    query MobileNavigationQuery {
      menu: allWordpressWpApiMenusMenusItems(
        filter: { wordpress_id: { eq: 3 } }
      ) {
        edges {
          node {
            items {
              title
              order
              type
              wordpress_id
              wordpress_parent
              url
              wordpress_children {
                title
                wordpress_parent
                url
                wordpress_id
                order
              }
            }
          }
        }
      }
    }
  `)

  const [edge] = edges
  const { items } = edge.node

  const filteredItems = items.map(item => {
    if (item.url.includes("/kategoria/")) {
      const url = item.url.replace("/kategoria", "")
      return { ...item, url }
    }
    return item
  })

  return (
    <StyledWrapper>
      <CloseIcon onClick={() => closeModal(false)}>
        <Close />
      </CloseIcon>
      <InnerWrapper>
        <StyledNavigation>
          <li>
            <StyledLink to="/">Home</StyledLink>
          </li>
          {filteredItems.map(item => (
            <li key={item.wordpress_id}>
              <StyledLink to={item.url}>{item.title}</StyledLink>
              {item.wordpress_children && (
                <React.Fragment>
                  <Chevron />
                  <StyledNestedNavigation>
                    {item.wordpress_children.map(child => (
                      <li key={child.wordpress_id}>
                        <StyledLink to={child.url}>{child.title}</StyledLink>
                      </li>
                    ))}
                  </StyledNestedNavigation>
                </React.Fragment>
              )}
            </li>
          ))}
        </StyledNavigation>
      </InnerWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 20;
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary};
  opacity: 0.7;
`

const InnerWrapper = styled.div`
  padding: 20px;
  min-height: 100%;
  overflow: hidden;
  overflow-y: auto;
`

const Chevron = styled.div`
  width: 7px;
  height: 7px;
  border: none;
  margin: 0 0 3px 7px;
  display: inline-block;
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
`

const CloseIcon = styled.div`
  top: 15px;
  z-index: 1;
  right: 15px;
  display: block;
  cursor: pointer;
  position: absolute;
  transform: scale(0.6);
`

const Navigtion = styled.ul`
  list-style: none;
  padding: 0 0 0 10px;
  margin: 0;
`

const StyledNavigation = styled(Navigtion)`
  > li {
    margin-bottom: 10px;
  }
`

const StyledNestedNavigation = styled(Navigtion)`
  padding: 5px 0 0 10px;
  font-size: 1.65rem;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:hover:active {
    text-decoration: underline;
  }
`

export default MobileNavigation
