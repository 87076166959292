import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { Container } from "../components/globalstyles.js"
import Logo from "./logo"
import Hamburger from "./hamburger"
import DesktopNavigation from "./navigation/desktopnavigation"

const Header = props => {
  return (
    <StyledHeader>
      <StyledContainer>
        <StyledLogo to="/">
          <Logo />
        </StyledLogo>
        <MenuIcon onClick={() => props.openModal(true)}>
          <Hamburger />
        </MenuIcon>
        <DesktopNavigation />
      </StyledContainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledContainer = styled(Container)`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`

const StyledHeader = styled.header`
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  position: fixed;
  background-color: #fac8c2;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
`

const StyledLogo = styled(Link)`
  line-height: 0;
`

const MenuIcon = styled.div`
  display: inherit;
  cursor: pointer;
  transform: scale(0.6);
  color: ${({ theme }) => theme.white};

  @media (min-width: 768px) {
    display: none;
  }
`

export default Header
